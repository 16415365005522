import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProductView from './ProductView';
import { getProfileDataAction, getProductDetails } from '../../actions';
import { QUERY_PARTNER } from '../../constants/queryStrings';
import { getStoriesAction } from '../../actions/storiesAction';
import { ai } from '../../telemetryService';
import AICustomEvents from '../../constants/appInsightEvents';
import MilanoCoffee from '../MilanoCoffee/MilanoCoffee';

const Product = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const roastInfo = useSelector((state) => state.roastInfo);
    const profile = useSelector((state) => state.profile);
    const posts = useSelector((state) => state.stories.posts);
    const language = useSelector((state) => state.translation.language);
    const milanoCoffeeIds = ['11162824', '11162822', '11162823', '11162825'];

    useEffect(() => {
        ai.trackEvent({ name: AICustomEvents.productDetailPage });
        window.scrollTo(0, 0);

        loadData();
    }, [id, language]);

    const loadData = () => {
        dispatch(getProductDetails(id));
        dispatch(getProfileDataAction(QUERY_PARTNER, id));
    };

    if ((roastInfo?.bagImageName || '').includes(id) && milanoCoffeeIds.includes(id)) {
        return <MilanoCoffee detail={roastInfo} profile={profile} posts={posts} isEnglish={language.includes('en')} />;
    }

    return (
        (roastInfo?.bagImageName || '').includes(id) && (
            <ProductView detail={roastInfo} profile={profile} posts={posts} isEnglish={language.includes('en')} />
        )
    );
};

export { Product };
