import React from 'react';

const LoadingSpinner = (type) => {
    return (
        <div className='loading-spinner'>
            <img
                src={
                    type === '11162824'
                        ? 'https://treaceabilitycdn.azureedge.net/images/Milano/lunaLoading.gif'
                        : 'https://treaceabilitycdn.azureedge.net/images/Milano/soleLoading.gif'
                }
                alt='Loading...'
            />
        </div>
    );
};

export default LoadingSpinner;
