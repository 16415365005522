import './styles.css';

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Detail } from '../common/DetailPage';
import Spectrum from '../common/RoastSpectrum';
import QuickFact from '../common/QuickFact';
import Profile from '../common/Profile';
import BrewMethod from '../common/BrewMethod';
import Images from '../../images';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Card from '@cxeweb/pattern-library/lib/components/card';
import countryCodes from '../../constants/countryCodes';
import {
    EXPLORE_LATIN_AMERICA_PATH,
    EXPLORE_AFRICA_PATH,
    EXPLORE_ASIAPACIFIC_PATH,
    EXPLORE_PRODUCTS_PATH,
    getCountryDetailPath,
} from '../../constants/paths';
import { Footer } from '../common/Footer';

const getRegionPath = (region) => {
    if (region.includes('asia')) {
        return EXPLORE_ASIAPACIFIC_PATH;
    } else if (region.includes('latin')) {
        return EXPLORE_LATIN_AMERICA_PATH;
    } else if (region.includes('africa')) {
        return EXPLORE_AFRICA_PATH;
    }

    return '/';
};

const ProductView = (props) => {
    const { t } = useTranslation();
    const {
        coffeeName,
        shortDescription,
        description,
        roast,
        roastScale,
        regions,
        body,
        acidity,
        processing,
        flavorNotes,
        foodPairing,
        suggestedBrewingMethod,
        posterImageName,
        decafText,
        specialFarmerCountry,
        sku,
    } = props.detail;
    let navigate = useNavigate();
    const { images, story, requestType, preferredName } = props.profile;
    const isCoreCoffee = !!roast;
    const getRegions = regions.split(',').map((region) => {
        const name = region.toLowerCase().trim();
        return {
            region,
            path: getRegionPath(name),
        };
    });

    const showLink = sku === '11122192' || sku === '11122193' || sku === '11099682'; //if huehuetenango, show Starbucks Story link

    return (
        <Detail title={coffeeName} backTitle=' '>
            <img
                src={posterImageName}
                alt='coffee bag'
                style={{
                    objectPosition: 'center',
                    maxHeight: '100',
                    maxWidth: '100',
                }}
            />
            {/* {isCoreCoffee && (
                <Spectrum roastType={roast} backgroundColor={'rgb(237, 235, 233)'} roastScale={roastScale} />
            )} */}
            <div className='product-section-container' style={{ backgroundColor: 'rgb(237, 235, 233)' }}>
                <table className={'table'}>
                    <tbody className='text-sm'>
                        {isCoreCoffee && (
                            <tr>
                                <td className='text-bold'>{t('product.roast')}</td>
                                <td className='flex-grow'>{roast}</td>
                            </tr>
                        )}
                        <tr>
                            <td className='text-bold'>{t('product.body')}</td>
                            <td className='flex-grow'>{body}</td>
                        </tr>
                        <tr>
                            <td className='text-bold'>{t('product.processing')}</td>
                            <td className='flex-grow'>{processing}</td>
                        </tr>
                        <tr>
                            <td className='text-bold'>{t('product.acidity')}</td>
                            <td className='flex-grow'>{acidity}</td>
                        </tr>
                        <tr>
                            <td className='text-bold'>{t('product.region')}</td>
                            <td className='td-regions flex-grow'>
                                {getRegions.map(({ region, path }) => (
                                    <Link className='color-greenStarbucks' to={path}>
                                        {region}
                                    </Link>
                                ))}
                            </td>
                        </tr>
                        {!!specialFarmerCountry && (
                            <tr>
                                <td className='text-bold'>{t('product.origin')}</td>
                                <td className='td-regions  flex-grow'>
                                    {specialFarmerCountry.split(',').map((countryCode) => {
                                        const code = countryCode.trim();
                                        return (
                                            <Link className='color-greenStarbucks' to={getCountryDetailPath(code, sku)}>
                                                {countryCodes[code]}
                                            </Link>
                                        );
                                    })}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='product-section-container mt-15'>
                <p className={'mb2'}>{description}</p>
                {showLink && props.isEnglish && (
                    <div style={{ textAlign: 'center' }}>
                        <a
                            className='color-greenStarbucks text-underline'
                            href='https://stories.starbucks.com/press/2017/starbucks-100million-coffee-trees/'
                        >
                            {t('product.huehuetenangoText')}
                        </a>
                    </div>
                )}
                <table>
                    <tbody>
                        {/* <QuickFact text={t('product.flavorNotes', { flavorNotes })} /> */}
                        <QuickFact text={shortDescription} />
                        <QuickFact text={t('product.foodPairing', { foodPairing })} />
                    </tbody>
                </table>
            </div>
            {decafText && (
                <div className='decaf-container'>
                    <div className='text-lg text-semibold p4'>{t('product.decaf.title')}</div>
                    <Card
                        imagePosition='top'
                        imageUrl={Images.decaf}
                        disableBoxShadow
                        contentClasses={'decaf-content p4'}
                    >
                        <span>{t('product.decaf.text')}</span>
                        <a
                            className='decaf-link'
                            href='https://starbuckscoffeeacademy.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {t('product.decaf.starbucksCoffeeAcademy')}
                        </a>
                    </Card>
                </div>
            )}
            <div className='product-section-container'>
                {suggestedBrewingMethod.length > 0 && (
                    <div className={'mb2'}>
                        <p className='text-lg text-semibold mt2 mb1'>{t('product.brewingRecommendations')}</p>
                        {suggestedBrewingMethod.map((data, index) => {
                            return (
                                <div key={index}>
                                    <BrewMethod method={data} isEnglish={props.isEnglish} />
                                    {suggestedBrewingMethod.length - 1 !== index && (
                                        <div style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} />
                                    )}
                                </div>
                            );
                        }, {})}
                    </div>
                )}
            </div>

            {story !== undefined && story !== '' && images !== undefined && images.length !== 0 && (
                <Profile picture={images[0]} story={story} type={requestType} preferredName={preferredName} />
            )}

            <div className='text-center mb3 pt3 pb5'>
                <Heading className='pl3 pt3 pb3 heading-xs text-semibold text-center' tagName='h4' size='sm'>
                    {t('product.otherCoffees')}
                </Heading>
                <Button
                    visualStyle='positive'
                    className='pl6 pr6 pt2 pb2'
                    onClick={() => navigate(EXPLORE_PRODUCTS_PATH)}
                >
                    {t('product.seeMoreProduct')}
                </Button>
            </div>
            <Footer />
        </Detail>
    );
};

export default ProductView;
