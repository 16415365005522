import './styles.css';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-sticky-el';
import Icon from '@cxeweb/pattern-library/lib/components/icon';
import iconPaths from '@cxeweb/pattern-library/lib/icons/__index';
import { Detail } from '../common/DetailPage';
import { TileCarousel } from '../common/TileCarousel';
import Images from '../../images';
import Button from '@cxeweb/pattern-library/lib/components/button';
import Heading from '@cxeweb/pattern-library/lib/components/heading';
import Card from '@cxeweb/pattern-library/lib/components/card';
import { SocialShare } from '../common';
import { getProductDetailPath } from '../../constants/paths';
import Text from '../common/Text';
import { getCountryDetailPath } from '../../constants/paths';
import { Footer } from '../common/Footer';
import ImageHeader from '../common/ImageHeader';
import LoadingSpinner from './LoadingSpinner'; // Make sure the path is correct

const getRandomNumber = Math.floor(Math.random() * 3);

const MilanoCoffee = (props) => {
    const { t } = useTranslation();
    const { coffeeName, description, bannerImageName, bagImageName, sku } = props.detail;
    let navigate = useNavigate();

    console.log('props =======>', props);

    const localeKey = ['11162824', '11162825'].includes(sku) ? 'sole' : 'luna';

    const seeAnotherSku =
        sku === '11162822'
            ? '11162824'
            : sku === '11162824'
            ? '11162822'
            : sku === '11162823'
            ? '11162825'
            : sku === '11162825'
            ? '11162823'
            : sku;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set a timer to hide the spinner after 3 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        <div>
            {loading ? (
                <LoadingSpinner type={sku} />
            ) : (
                <Detail title={coffeeName} backTitle=' '>
                    <ImageHeader
                        image={bagImageName}
                        title={coffeeName}
                        detail={t('home.ethicalText')}
                        coffeeDetails={t(`milano:top.${localeKey}.note`)}
                    />
                    <div className={'pt5'}>
                        <div className={`background ${localeKey}`}>
                            <div>
                                <div className='video-container'>
                                    <video width='100%' height='auto' controls>
                                        <source
                                            src='https://treaceabilitycdn.azureedge.net/images/Milano/Milano.mp4'
                                            type='video/mp4'
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                                    {t(`milano:origins.header`)}
                                </Heading>
                                <div>
                                    <p className='text-sm p3 mb3'>{t(`milano:origins.${localeKey}.text`)}</p>
                                </div>
                            </div>

                            <div className='pb3 pt1 og-cbg-ctn'>
                                {/* Origin bar */}
                                <div className='cbg-container-1'>
                                    {/* <div className='cbg-percentage-container-1'>
                                        <div className='cbg-style-for-cup-1'>
                                            <div className='cbg-percentage'>100</div>
                                            <div className='cbg-percentage'>0</div>
                                        </div>
                                    </div> */}
                                    <div className='cbg-barContainer-1'>
                                        <div className='cbg-percentage-container-1'>
                                            <div className='cbg-percentage-top'>100</div>
                                            <div className='cbg-percentage-bottom'>0</div>
                                        </div>
                                    </div>
                                    <div className='cbg-barContainer-1'>
                                        <div className='cbg-style-for-cup-1'>
                                            <div
                                                style={{
                                                    backgroundColor: '#534A9F',
                                                    width: '100%',
                                                    height: '40%',
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    backgroundColor: '#B683BB',
                                                    width: '100%',
                                                    height: '60%',
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className={'cbg-dataContainer'}>
                                        <div
                                            className={'cbg-singleData'}
                                            onClick={() => navigate(getCountryDetailPath('IDNSUM', sku))}
                                        >
                                            <div className='cbg-pointer' style={{ backgroundColor: '#534A9F' }} />
                                            <img
                                                src={Images.mapSumatraIndonesia}
                                                style={{ width: '45px', marginLeft: '10px' }}
                                                alt={t('sumatra')}
                                            ></img>
                                            <div style={{ flex: 3, paddingLeft: '10px' }}>
                                                <div className='cbg-title'>{t(`country:IDNSUM`)}</div>
                                            </div>
                                            <div className='text-right' style={{ width: '5%' }}>
                                                <Icon
                                                    path={iconPaths.chevronRight}
                                                    size='16px'
                                                    className='color-textBlackSoft text-right'
                                                />
                                            </div>
                                        </div>
                                        {/* farmer 1 */}
                                        <div
                                            className='farmer-profile story-container mb5 mt4'
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Card
                                                style={{ marginTop: '-10px', position: 'relative' }}
                                                contentClasses='pl3 pr3 pt3 pb2 profile-card'
                                                containerClasses={'ml2 mr2'}
                                            >
                                                <div className='profile-image-container pl2 pr2'>
                                                    <img
                                                        className='profile-image'
                                                        src={`https://treaceabilitycdn.azureedge.net/images/Milano/${localeKey}Farmer1.png`}
                                                        alt={'a pic of a person'}
                                                    />
                                                </div>
                                                <Heading
                                                    tagName='h4'
                                                    className='mb3 pt3 heading-xs text-semibold text-left'
                                                >
                                                    {t(`milano:origins.${localeKey}.farmer1Header`)}
                                                </Heading>
                                                <p className='text-bold text-left'>
                                                    {t(`milano:origins.${localeKey}.farmer1Question`)}
                                                </p>
                                                <p className='text-left'>
                                                    {t(`milano:origins.${localeKey}.farmer1Answer`)}
                                                </p>
                                            </Card>
                                        </div>
                                        <div
                                            className={'cbg-singleData'}
                                            onClick={() => navigate(getCountryDetailPath('COL', sku))}
                                        >
                                            <div className='cbg-pointer' style={{ backgroundColor: '#B683BB' }} />
                                            <img
                                                src={Images.mapColombia}
                                                style={{ width: '45px', marginLeft: '10px' }}
                                                alt={t('sumatra')}
                                            ></img>
                                            <div style={{ flex: 3, paddingLeft: '10px' }}>
                                                <div className='cbg-title'>{t(`country:COL`)}</div>
                                            </div>
                                            <div className='text-right' style={{ width: '5%' }}>
                                                <Icon
                                                    path={iconPaths.chevronRight}
                                                    size='16px'
                                                    className='color-textBlackSoft text-right'
                                                />
                                            </div>
                                        </div>
                                        {/* farmer 2 */}
                                        <div
                                            className='farmer-profile story-container  mt4'
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Card
                                                style={{ marginTop: '-10px', position: 'relative' }}
                                                contentClasses='pl3 pr3 pt3 pb2 profile-card'
                                                containerClasses={'ml2 mr2'}
                                            >
                                                <div className='profile-image-container pl2 pr2'>
                                                    <img
                                                        className='profile-image'
                                                        src={`https://treaceabilitycdn.azureedge.net/images/Milano/${localeKey}Farmer2.png`}
                                                        alt={'a pic of a person'}
                                                    />
                                                </div>
                                                <Heading
                                                    tagName='h4'
                                                    className='mb3 pt3 heading-xs text-semibold text-left'
                                                >
                                                    {t(`milano:origins.${localeKey}.farmer2Header`)}
                                                </Heading>
                                                <p className='text-bold text-left'>
                                                    {t(`milano:origins.${localeKey}.farmer2Question`)}
                                                </p>
                                                <p className='text-left'>
                                                    {t(`milano:origins.${localeKey}.farmer2Answer`)}
                                                </p>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* roster */}
                            <div>
                                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                                    {t(`milano:roasting.header`)}
                                </Heading>
                                <div>
                                    <p className='text-sm p3 mb3'>{t(`milano:roasting.${localeKey}.text`)}</p>
                                </div>
                            </div>
                            <div className='story-container mb5 mt4' style={{ textAlign: 'center' }}>
                                <Card
                                    style={{ marginTop: '-10px', position: 'relative' }}
                                    contentClasses='pl3 pr3 pt3 pb2 profile-card'
                                    containerClasses={'ml2 mr2'}
                                >
                                    <div className='profile-image-container pl2 pr2'>
                                        <img
                                            className='profile-image'
                                            src={`https://treaceabilitycdn.azureedge.net/images/Milano/${localeKey}Roaster.png`}
                                            alt={'a pic of a person'}
                                        />
                                    </div>
                                    <Heading tagName='h4' className='mb3 pt3 heading-xs text-semibold text-left'>
                                        {t(`milano:roasting.${localeKey}.roasterHeader`)}
                                    </Heading>
                                    <p className='text-bold text-left'>
                                        {t(`milano:roasting.${localeKey}.roasterQuestion`)}
                                    </p>
                                    <p className='text-left'>{t(`milano:roasting.${localeKey}.roasterAnswer`)}</p>
                                </Card>
                            </div>
                            {/* barista */}
                            <div>
                                <Heading className='pl3 pt3 heading-xs text-semibold' tagName='h3' size='md'>
                                    {t(`milano:coffee.header`)}
                                </Heading>
                                <div>
                                    <p className='text-sm p3 mb3'>
                                        {t(`milano:coffee.champion`)}
                                        <span className='text-sm'>{t(`milano:coffee.embodiment`)}</span>{' '}
                                        {t(`milano:coffee.${localeKey}.tryCoffee`)}
                                    </p>
                                </div>
                            </div>
                            <div className='story-container mb5 mt4' style={{ textAlign: 'center' }}>
                                <Card
                                    style={{ marginTop: '-10px', position: 'relative' }}
                                    contentClasses='pl3 pr3 pt3 pb2 profile-card'
                                    containerClasses={'ml2 mr2'}
                                >
                                    <div className='profile-image-container pl2 pr2'>
                                        <img
                                            className='profile-image'
                                            src={`https://treaceabilitycdn.azureedge.net/images/Milano/barista/${t(
                                                `milano:coffee.baristaProfiles.barista${getRandomNumber}.image`
                                            )}`}
                                            alt={'barista'}
                                        />
                                    </div>
                                    <Heading tagName='h4' className='mb3 pt3 heading-xs text-semibold text-left'>
                                        {t(`milano:coffee.meet`)}{' '}
                                        {t(`milano:coffee.baristaProfiles.barista${getRandomNumber}.name`)}
                                    </Heading>
                                    <p className='text-bold text-left'>
                                        {t(`milano:coffee.baristaProfiles.barista${getRandomNumber}.question`)}
                                    </p>
                                    <p className='text-left'>
                                        {t(`milano:coffee.baristaProfiles.barista${getRandomNumber}.answer`)}
                                    </p>
                                </Card>
                            </div>
                            <div>
                                <Card
                                    imagePosition='top'
                                    imageUrl={bannerImageName}
                                    disableBoxShadow
                                    style={{ background: '#f7f7f7' }}
                                    onClick={() => {}}
                                />
                                <Card disableBoxShadow style={{ background: '#f7f7f7' }} contentClasses={'pb7'}>
                                    <Text className='p4'>{description}</Text>
                                    <Button
                                        className='ml6'
                                        onClick={() => navigate(getProductDetailPath(seeAnotherSku))}
                                    >
                                        {t(`milano:coffee.${localeKey}.seeAnother`)}
                                    </Button>
                                </Card>
                            </div>

                            <div className='hm-social-share'>
                                <Sticky
                                    className='story-stick'
                                    mode='bottom'
                                    stickyClassName='story-stick--sticky'
                                    positionRecheckInterval={25}
                                >
                                    <SocialShare
                                        postTitle={t('home.socialShare')}
                                        postUrl={`https://traceability.starbucks.com/#/`}
                                        image={Images.globalAcdemy}
                                        style={{
                                            paddingBottom: '60px',
                                        }}
                                    />
                                </Sticky>
                            </div>

                            <Card disableBoxShadow style={{ background: '#edebe9' }}>
                                <TileCarousel />
                            </Card>
                            <Footer />
                        </div>
                    </div>
                </Detail>
            )}
        </div>
    );
};

export default MilanoCoffee;
