export const RETURNING_FAV_LIST = [
    { sku: '11002143', name: 'product:thanksgivingBlend' },
    { sku: '11002752', name: 'product:christmasBlend' },
    { sku: '11002765', name: 'product:decafChristmasBlend' },
    { sku: '11031596', name: 'product:holidayBlend' },
    { sku: '11142270', name: 'product:greenApronBlend' },
    { sku: '11162822', name: 'product:milanoLuna' },
    { sku: '11162824', name: 'product:milanoSole' },
];

export const CORE_PRODUCTS_LIST = [
    { sku: '11130114', name: 'product:pikePlaceRoast' }, //11028470 - mojito: 11130114
];

export const RESERVE_PRODUCTS_LIST = [
    { sku: '11080488', name: 'product:christmas' }, //reserve christmas
    { sku: '11168481', name: 'product:colombiaLasMargaritasSudanRume' }, //reserve Colombia Las Margaritas Sudan Rume FY25 Holiday
    { sku: '11073989', name: 'product:decafCostaRicaHaciendaAlsacia' },
    { sku: '11079357', name: 'product:princiBlend' },
];

export const EXPLORE_LIST = [...RETURNING_FAV_LIST, ...CORE_PRODUCTS_LIST, ...RESERVE_PRODUCTS_LIST];

// export const CORE_PRODUCTS_BLONDE = [
//     { sku: '11099682', name: 'product:blondeEspresso' },
//      { sku: '11130046', name: 'product:verandaBlend' }, //FY23 Summer,FY24 winter
//     { sku: '11032562', name: 'product:trueNorthBlend' },
// ];

// export const CORE_PRODUCTS_MEDIUM = [
//{ sku: '11130114', name: 'product:pikePlaceRoast' }, //11028470 - mojito: 11130114
//     { sku: '11048714', name: 'product:coldBrew' },
//     { sku: '00190135', name: 'product:decafPikePlaceRoast' },
//     { sku: '11028499', name: 'product:guatemalaAntigua' },
//     { sku: '00195704', name: 'product:iceCoffeeBlend' },
//     { sku: '11030166', name: 'product:kona' },
//     { sku: '11028476', name: 'product:yukon' },
//     { sku: '11028470', name: 'product:pikePlaceRoast' },
//     { sku: '00250033', name: 'product:pikePlaceRoastSpecial' },
//    { sku: '11130108', name: 'product:sirenBlend' }, //11104434 - mojito: 11130108
// ];

// export const CORE_PRODUCTS_DARK = [
//     { sku: '11028485', name: 'product:decafEspresso' },
//     { sku: '11086773', name: 'product:decafSumatra' },
//      { sku: '11130112', name: 'product:espresso' }, //11028472 - mojito: 11130112
//     { sku: '11011972', name: ('product:fairTradeEspresso') },
//     { sku: '11028506', name: 'product:fairTradeItalianRoast' },
//     { sku: '11032568', name: ('product:goldCoast') },
//     { sku: '11130111', name: 'product:komodoDragonBlend' },//11028501 - mojito: 11130111
//     { sku: '00007304', name: ('product:signature') },
//        { sku: '11130110', name: 'product:sumatra' }, //11028479 -mojito: 11130110
//     { sku: '11130115', name: 'product:verona' },// 11028503 mohito:11130115
// ];

// export const RETURNING_FAV_SUMMER = [
//     { sku: '11142270', name: 'product:greenApronBlend' },
// ];
// export const RETURNING_FAV_WINTER = [
//     { sku: '11128417', name: 'product:tributeBlend' },//winter
// ];
// export const RETURNING_FAV_SPRING = [
//     { sku: '11002186', name: 'product:anniversaryBlend' },//spring
//     { sku: '11120116', name: 'product:odysseyBlend' }, //spring
// ];

// export const RETURNING_FAV_FALL = [{ sku: '00185762', name: 'product:guatemalaCasiCielo' }];

// export const RETURNING_FAV_HOLIDAY = [
//     { sku: '11002143', name: 'product:thanksgivingBlend' },
//     { sku: '11002752', name: 'product:christmasBlend' },
//     { sku: '11022752', name: 'product:christmasBlendGround' },
//     { sku: '11007935', name: 'product:christmasBlendEspresso' },
//     { sku: '11002765', name: 'product:decafChristmasBlend' },
//     { sku: '11031596', name: 'product:holidayBlend' },
// ];

// export const RESERVE_PRODUCTS_WHOLE_LIST = [
//     { sku: '11104923', name: 'product:boliviaSolDeLaManana' },//summer
//     { sku: '11115015', name: 'product:brazilIsidroPereiraEstate' }, //spring
//     { sku: '11080488', name: 'product:christmas' },//reserve christmas
//     { sku: '11095733', name: 'product:costaRicaNaranjo' },
//     { sku: '11073989', name: 'product:decafCostaRicaHaciendaAlsacia' },
//     { sku: '11123850', name: 'product:ecuadorLoja' }, //spring
//     { sku: '11122192', name: 'product:ethiopiaYirgacheffeChelelektu' }, //winter
//     { sku: '11099524', name: 'product:galapagosLaTortuga' }, //winter
//     { sku: '11124495', name: 'product:guatemalaElSocorro' }, //spring
//     { sku: '11122192', name: 'product:guatemalaHuehuetenango' }, //winter
//     { sku: '11092068', name: 'product:hawaiiKau' },//fall
//     { sku: '11069202', name: 'product:jamaicaBlueMountain' }, //winter
//     { sku: '11119636', name: 'product:kenyaBaragwi' },
//     { sku: '11113403', name: 'product:microblend10' },
//     { sku: '11062990', name: 'product:microblend21' },
//     { sku: '11079357', name: 'product:princiBlend' },
//     { sku: '11116716', name: 'product:rwandaHingakawa' },//summer
//     { sku: '11125154', name: 'product:sumatraKerinci' }, //spring
//     { sku: '11114398', name: 'product:sundriedPapuaNewGuineaUlya' }, //spring
//     { sku: '11115015', name: 'product:brazilIsidroPereiraEstate' }, //spring
//     { sku: '11114398', name: 'product:sundriedPapuaNewGuineaUlya' }, //spring
//     { sku: '11123850', name: 'product:ecuadorLoja' }, //spring
//     { sku: '11125922', name: 'product:colombiaFincaLaReserva' },//summer
//     { sku: '11118421', name: 'product:brazilSantuarioSulGeshaVarietal' },//summer
// { sku: '11130401', name: 'product:puertoRicoYaucoSelecto' },//fall
// { sku: '11077505', name: 'product:vietnamDaLat' },//fall
//{ sku: '11128383', name: 'product:panamaBoquete' },//fall
//{ sku: '11131842', name: 'product:guatemalaSantaClaraEstate' },//winter
// { sku: '11131696', name: 'product:sunDriedEthiopiaGeraFarm' },//winter
// { sku: '11114072', name: 'product:costaRicaHoneyProcess' },//winter
// { sku: '11133488', name: 'product:brazilFazendaCatanduva' }, //spring
// { sku: '11133490', name: 'product:papuaNewGuineaMoanti' }, //spring
// { sku: '11133492', name: 'product:baliHoneyProcess' }, //spring
// { sku: '11078413', name: 'product:colombiaCerroAzulGeshaVarietal' }, //spring
// { sku: '11069196', name: 'product:nicaraguaMaracaturra' }, //spring
// { sku: '11139386', name: 'product:sunDriedZambiaNgoliEstate' }, //fall
// { sku: '11142963', name: 'product:guatemalaLakeAtitlan' }, //winter
// { sku: '11143355', name: 'product:honeyProcessedHondurasLosNaranjos' }, //winter
//    { sku: '11144449', name: 'product:rwandaSholi' }, //FY23 Summer
//  { sku: '11140612', name: 'product:reserveSumatraWahanaEstateMicroblend' }, //FY23/24 fall
//{ sku: '11072628', name: 'product:reserveMalawiSableFarms' }, //FY23/24 fall
//{ sku: '11069201', name: 'product:reserveSundriedEthiopiaKayonMountainFarm' }, //FY24 winter
// { sku: '11155225', name: 'product:reserveColombiaNarinoGranos' }, //FY24 summer
// { sku: '11154947', name: 'product:reserveBaliMountBatur' }, //FY24 summer
// { sku: '11154951', name: 'product:reserveRwandaSholiKundwa' }, //FY24 summer

// { sku: '11162042', name: 'product:reserveHondurasCafico' }, //FY24/25 fall
// { sku: '11161468', name: 'product:reserveTanzaniaIyenga' }, //FY24/25 fall
// { sku: '11161561', name: 'product:sundriedUgandaEmbogo' }, //FY24/25 fall
// ];
